$(document).on('turbolinks:load', () => {
  $('.status-options').on('change', function() {
    $('.add-enterprise-status').css('display', 'flex');
    let key = $(this).find(":selected").val();

    $('#enterprise_status_option_id').val(key)
    $('.options-select-status').hide();
    $(`.option-${key}`).show();
  });

  $('.status2-options').on('change', function() {
    $('.add-enterprise-status2').css('display', 'flex');
    let key = $(this).find(":selected").val();

    $('#enterprise_status2_option_id').val(key)
    $('.options-select-status2').hide();
    $(`.option-${key}-2`).show();
  });

  $('.subphase-options').on('change', function() {
    $('.add-enterprise-subphase').css('display', 'flex');
    let key = $(this).find(":selected").val();

    $('#enterprise_subphase_option_id').val(key)
    $('.options-select-subphase').hide();
    $(`.option-${key}-subphase`).show();
  });

  $('.delete-business-unit').click(function(e) {
    e.preventDefault();

    Swal.fire({
      icon: "warning",
      title: "¿Está seguro que desea eliminar esta Unidad de negocio?",
      text: `Esta operación es irreversible. 
      Todos las planeaciones y proyectos asociados se eliminarán.`,
      showCancelButton: true,
      confirmButtonText: "Sí, Eliminar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();
        let businessUnitId = $(this).parent().parent().find(".business_unit_id").text()
        var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");

        formData.append('authenticity_token', AUTH_TOKEN);
        formData.append('business_unit_id', businessUnitId);

        $.ajax({
          type: "POST",
          url: "/business_units/destroy",
          data: formData,
          contentType: false,
          processData: false,
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            if ( response["error"] ) {
              Swal.fire({
                title: "Alerta",
                text: "El Proyecto no puede ser eliminado debido a que el saldo en alguna cuenta es diferente a $0. Favor de contactar a los administradores del software.",
                icon: "warning",
                confirmButtonText: "Ok"
              });
            } else {
              if ( response["success"] ) {
                Swal.fire({
                  title: "Alerta",
                  text: "La unidad de negocio se ha eliminado exitosamente",
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Ok"
                }).then((result) => {
                  Turbolinks.visit(window.location)
                })
              } else {
                Swal.fire({
                  title: "Alerta",
                  text: "El Proyecto no puede ser eliminado debido a que se encuentra asociado a una cuenta ó posee registros asociados.",
                  icon: "warning",
                  confirmButtonText: "Ok"
                })
              }
            }
          }
        });
      }
    });
  });

  $('.new-business-unit').click((event) => {
    event.preventDefault();
    let addAreas = $('#modal-new-business-unit');
    addAreas.css("visibility", "visible").animate({opacity: 1});
    $('#business_unit_name').val('');
    $('#business_unit_name_confirmation').val('');
    $("#logo-pre-load").empty();
    $("#logo-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
    '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
      '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
      '<div class="flex-1 pl-1 mr-16">' +
        '<div class="font-medium">No hay logo seleccionado</div>' +
      '</div>' +
      '<div class="text-gray-600 text-xs"></div>' +
    '</div>' +
    '</li>');
  });

  $('.update-logo-enterprise').click((event) => {
    event.preventDefault();

    let modalLogo = $('#modal-logo-enterprise');

    modalLogo.css("visibility", "visible").animate({opacity: 1});
    $("#list-upload-logo").empty();
    $("#list-upload-logo").append('<li class="border-gray-200 flex flex-row mb-2">' +
    '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
      '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
      '<div class="flex-1 pl-1 mr-16">' +
        '<div class="font-medium">No hay logo seleccionado</div>' +
      '</div>' +
      '<div class="text-gray-600 text-xs"></div>' +
    '</div>' +
    '</li>');
  });

  $('.edit-business-unit').click((event) => {
    event.preventDefault();
    let addAreas = $('#modal-edit-business-unit');
    addAreas.css("visibility", "visible").animate({opacity: 1});
    let id = $(event.target).closest('li').find('.business_unit_id').text();
    let name = $(event.target).closest('li').find('.business_unit_name').text();
    $('#edit_business_unit_name').val(name);
    $('#edit_business_unit_name_confirmation').val(name);
    $('#edit_business_unit_id').val(id)
    $("#edit-logo-pre-load").empty();
    $("#edit-logo-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
    '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
      '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
      '<div class="flex-1 pl-1 mr-16">' +
        '<div class="font-medium">No hay logo seleccionado</div>' +
      '</div>' +
      '<div class="text-gray-600 text-xs"></div>' +
    '</div>' +
    '</li>');
  });

  $('.edit-status').click((event) => {
    event.preventDefault();
    showModalEdit();
    setInputsEditStatus(event);
  });

  $('.edit-status-2').click((event) => {
    typeStatusEdit = '2';

    event.preventDefault();
    showModalEdit();
    setInputsEditStatus(event, typeStatusEdit);
  });

  $('.edit-subphase').click((event) => {
    typeStatusEdit = '3';

    event.preventDefault();
    showModalEdit();
    setInputsEditStatus(event, typeStatusEdit);
  });
  
  $('.close-modal').click(() => {
    $('.modal').hide();
  });

  $('.close-modal-business-unit').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-new-business-unit');
    hideVisibilityAnimation('#modal-edit-business-unit');
  });

  $('.close-modal-status').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-edit-status');
  });

  $('.close-modal-logo-enterprise').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-logo-enterprise');
  });

  const hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);
  
    modal.css("visibility", "hidden");
    modal.css("opacity", "0")
  };

  $('#newBusinessUnit').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    let buName = $('#business_unit_name').val();
    let confirmName = $('#business_unit_name_confirmation').val();
    formData.append('name',buName);
    formData.append('name_confirmation',confirmName);
    $.each($("#logo_file"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#logo_file").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/business_units/create',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      beforeSend: function () {
        showLoading();
      },
      success: function (data) {
        Swal.close();
      }
    });
  });

  $('#editBusinessUnit').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    let buName = $('#edit_business_unit_name').val();
    let confirmName = $('#edit_business_unit_name_confirmation').val();
    let id = $('#edit_business_unit_id').val();
    formData.append('name',buName);
    formData.append('name_confirmation',confirmName);
    formData.append('id',id);
    $.each($("#edit_logo_file"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#edit_logo_file").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/business_units/update',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      beforeSend: function () {
        showLoading();
      },
      success: function (data) {
        Swal.close();
        hideVisibilityAnimation('#modal-edit-business-unit');
        location.reload(); 
      }
    });
  });

  $('#logo_file').change(function () {
    $("#logo-pre-load").empty();
    $.each($("#logo_file"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#logo_file").val('')
          return
        }
        $("#logo-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#logo_file").val() == '') {
      $("#logo-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });
  
  $('#edit_logo_file').change(function () {
    $("#edit-logo-pre-load").empty();
    $.each($("#edit_logo_file"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#edit_logo_file").val('')
          return
        }
        $("#edit-logo-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#edit_logo_file").val() == '') {
      $("#edit-logo-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  $('#submit_BU').click(function () {
    hideVisibilityAnimation('#modal-new-business-unit');
  });

  $('#update-logo-file').change(function () {
    $("#list-upload-logo").empty();
    $.each($("#update-logo-file"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#update-logo-file").val('')
          
          return
        }
        $("#list-upload-logo").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#update-logo-file").val() == '') {
      $("#list-upload-logo").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  $('#updateLogoEnterprise').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    $.each($("#update-logo-file"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#update-logo-file").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/pages/change_logo',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      beforeSend: function () {
        showLoading();
      },
      success: function (data) {
        Swal.close();
        hideVisibilityAnimation('#modal-edit-business-unit');
        location.reload(); 
      }
    });
  });

  function showLoading() {
    let timerInterval
    Swal.fire({
      title: 'Generando Unidad',
      html: 'Espera un momento, estamos generando los datos necesarios.',
      didOpen: () => {
        Swal.showLoading()
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    })
  };

  function showModalEdit() {
    let addAreas = $('#modal-edit-status');

    addAreas.css("visibility", "visible").animate({opacity: 1});
  }

  function setInputsEditStatus(event, typeStatusEdit = '') {

    let id = $(event.target).closest("li").find(".status_id").text().trim();
    let name = $(event.target).closest("li").find(".status_name").text().trim();
    let titleStatus = 'Editar nombre del status'

    $("#edit_status_name").val(name);
    $("#edit_status_id").val(id);
    $("#status-edit-type").val('');

    if (typeStatusEdit == '3') {
      titleStatus = 'Editar nombre de la subfase'
    
      $("#modal-title-status-edit").text(`${titleStatus}`);
      $("#status-edit-type").val(typeStatusEdit);
    } else {
    
      $("#modal-title-status-edit").text(`${titleStatus} ${typeStatusEdit}`);
      $("#status-edit-type").val(typeStatusEdit);
    }
  }
});